<template>
	<div style="height: 100%; display: flex; flex-direction: column;">
		<el-alert type="warning" description="当产品上架时不能修改属性，请下架后再操作。" v-if="product.sold_at"></el-alert>
		<el-alert type="warning" description="产品使用表单控件时，只能直接结算，不能添加到购物车。即购买产品时让买家输入留言表单，最多可设置 10 条。" v-else></el-alert>
		<div style="height: 100%; overflow: auto;">
			<el-table class="scroll-wrapper" height="100%" :header-cell-style="{backgroundColor: '#e1d6ba', color: '#666'}" :data="product.goods_controls" :size="theme.size">
				<el-table-column label="名称" min-width="100">
					<el-link slot-scope="scope" type="primary" @click="controls_dialog = true; control = {...scope.row}" :disabled="!$utils.update($api.URI_GOODS_CONTROLS) || product.sold_at">{{scope.row.name}}</el-link>
				</el-table-column>
				<el-table-column label="类型" prop="type" min-width="80" :formatter="(r) => { return control_types[r.type]; }"></el-table-column>
				<el-table-column label="必填" prop="required" min-width="60" :formatter="(r) => { return r.required ? '是' : '否'; }"></el-table-column>
				<el-table-column label="占位符" prop="placeholder" min-width="180"></el-table-column>
				<el-table-column min-width="60" v-if="!product.sold_at">
					<el-link slot="header" type="primary" @click="controls_dialog = true; control = {goods_id: product.id, number: product.number }" :disabled="!$utils.create($api.URI_GOODS_CONTROLS) || (product.goods_controls && product.goods_controls.length > 9) ">新增</el-link>
					<template slot-scope="scope">
						<el-popconfirm title="确定要恢复这个控件吗？" @confirm="handleControls('delete', scope.row)" v-if="!scope.row.deleted_at">
							<el-link slot="reference" type="primary" :disabled="!$utils.delete($api.URI_GOODS_CONTROLS)">删除</el-link>
						</el-popconfirm>
						<el-link type="primary" :disabled="!$utils.restore($api.URI_GOODS_CONTROLS)" @click="handleControls('restore', scope.row)" v-else>恢复</el-link>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog width="420px" :title="product.title+'控件'" :visible.sync="controls_dialog" :fullscreen="theme.width <= 420" append-to-body destroy-on-close>
			<el-form ref="controls_form" size="mini" :model="control" :rules="control_rules">
				<el-form-item prop="name" label="名称">
					<el-input v-model="control.name" placeholder="请输入名称" clearable>
						<el-select slot="prepend" style="width: 100px;" placeholder="请选择类型" v-model="control.type" :disabled="control.id!==undefined">
							<el-option v-for="(type, t) in control_types" :key="t" :label="type" :value="t"></el-option>
						</el-select>
						<el-checkbox slot="append" v-model="control.required" :true-label="1" :false-label="0">必填</el-checkbox>
					</el-input>
				</el-form-item>
				<el-form-item prop="placeholder" label="占位符">
					<el-input v-model="control.placeholder" placeholder="请输入占位符" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('controls_form')" :disabled="!$utils.create($api.URI_GOODS_CONTROLS)">提交保存</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		props: {
			product: {
				type: Object
			}
		},
		computed: {
			...mapState(['theme', 'goods']),
			control_types () {
				return this.goods.control_types || [];
			}
		},
		methods: {
			async handleControls (action, r) {
				const res = await this.$http.get(this.$api.URI_GOODS_CONTROLS, {params: {action, id: r.id}, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.$emit('refresh');
					}
				});
			},
			async submitControls (data) {
				const res = await this.$http.post(this.$api.URI_GOODS_CONTROLS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.$emit('refresh');
						this.controls_dialog = false;
						this.control = {};
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'controls_form':
							this.submitControls(this.control);
						break;
					}
				});
			}
		},
		data() {
			return {
				control: {},
				controls_dialog: false,
				control_rules: {
					name: [{ required: true, message: '请输入控件名称！', trigger: 'blur' }]
				}
			}
		}
	};
</script>