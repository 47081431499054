<template>
	<div style="height: 100%;">
		<template v-if="theme.is_mobile && theme.width < 750">
			<div class="head">
				<div>{{params.page}} / {{Math.ceil(params.total/params.perPage)}}，共 {{params.total}} 条记录</div>
				<div @click="$toast('请使用电脑端')">筛选</div>
			</div>
			<div class="wrap">
				<van-pull-refresh v-model="pull_down_loading" @refresh="onRefresh" style="min-height: 100%;">
					<div class="item" v-for="item in goods" :key="item.id" @click="$toast('更多操作请使用电脑端')">
						<div class="title">{{item.number}} {{item.title}}{{item.title}}</div>
						<div class="mate"><del>{{item.market_price/100}} 元</del> · {{item.shop_price/100}} 元 · {{item.weight}} 克 · 剩 {{item.stock}} · {{item.created_at}}</div>
					</div>
				</van-pull-refresh>
			</div>
			<van-pagination v-model="params.page" :total-items="params.total" :items-per-page="params.perPage" @change="(v) => { getContractsFees({ ...params, page: v }) }"></van-pagination>
		</template>
		<template v-else>
			<main-table>
				<el-form slot="header" :inline="true" :model="params" :size="theme.size" @submit.native.prevent>
					<el-form-item>
						<el-input v-model="params.keywords" placeholder="产品标题/编码" @keyup.enter.native.prevent="getGoods(params)" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-input v-model="params.attrs" placeholder="产品属性名称/编码" @keyup.enter.native.prevent="getGoods(params)" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-select style="width: 100%;" placeholder="请选择标签" v-model="params.tags" :remote-method="remoteMethod" :loading="tags_loading" :multiple-limit="5" remote multiple filterable default-first-option>
							<el-option v-for="(tag, t) in tags" :key="t" :label="tag" :value="tag"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="!admin_user.shop_id">
						<el-select v-model="params.shop_id" placeholder="所属门店" filterable clearable>
							<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-cascader placeholder="请选择类目" v-model="params.categories" :options="tree_categories" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.brand" placeholder="请选择品牌" filterable multiple collapse-tags>
							<el-option v-for="brand in brands" :key="brand.id" :label="brand.name" :value="brand.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.goods_type_id" placeholder="请选择类型" filterable multiple collapse-tags>
							<el-option :key="0" label="单商品" :value="0"></el-option>
							<el-option v-for="type in goods_types" :key="type.id" :label="type.name" :value="type.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable>
							<el-option label="创建时间" value="created"></el-option>
							<el-option label="更新时间" value="updated"></el-option>
							<el-option label="推荐时间" value="recommend"></el-option>
							<el-option label="精品时间" value="boutique"></el-option>
							<el-option label="上架时间" value="sold"></el-option>
							<el-option label="热销时间" value="hot"></el-option>
						</el-select>
						<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getGoods({ ...params, page: 1})">查询</el-button>
					</el-form-item>
				</el-form>
				<el-table slot="table" ref="goods_table" class="scroll-wrapper absolute" height="100%" :data="tables" @sort-change="sortChange" :size="theme.size">
					<el-table-column type="selection" width="42"></el-table-column>
					<!-- <el-table-column prop="views" label="图片/浏览" width="120" sortable="custom">
						<div slot-scope="scope" style="position: relative;">
							<el-image class="thumb" fit="fit" :src="scope.row.img" :preview-src-list="scope.row.attachments.map((g) => {return g.url;})">
								<i slot="error" class="el-icon-picture-outline"></i>
							</el-image>
							<span class="view-tag">{{scope.row.views}}</span>
						</div>
					</el-table-column> -->
					<el-table-column prop="number" label="名称/编码" min-width="380" sortable="custom">
						<template slot-scope="scope">
							<el-link type="primary" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" @click="$refs['show_dialog'].open(scope.row)" :disabled="scope.row.deleted_at">{{scope.row.title}}</el-link>
							<p style="margin-top: 2px;">
								<el-tooltip placement="right">
									<vue-qr slot="content" :margin="10" :size="100" :text="'/#/views/goods/show?id='+scope.row.id"></vue-qr>
									<el-link type="primary" :underline="false" @click="$utils.openWindow($config.ROOTPATH+'goods/'+scope.row.id)" :disabled="scope.row.deleted_at">{{scope.row.number}}</el-link>
								</el-tooltip>
								<span v-if="!admin_user.shop_id && scope.row.shop"> · {{scope.row.shop.name}}</span>
								<el-tooltip placement="top" v-if="scope.row.category" :content="'类目关联 '+scope.row.category.goods_count+' 个商品'">
									<span> · {{scope.row.category.name}}</span>
								</el-tooltip>
								<el-tooltip placement="top" v-if="scope.row.brand" :content="'品牌关联 '+scope.row.brand.goods_count+' 个商品'">
									<span> · {{scope.row.brand.name}}</span>
								</el-tooltip>
								<span v-if="scope.row.goods_type"> · {{scope.row.goods_type.name}}<template v-if="scope.row.goods_type.type_attributes.length">【{{scope.row.goods_type.type_attributes.map((attr) => { return attr.name; }).join('_')}}】</template></span>
								<span v-else>单商品</span>
							</p>
							<div>
								<el-checkbox :size="theme.size" @change="changeGoods({ action: 'recommend', id: scope.row.id})" :value="scope.row.recommend_at!=null" :disabled="!$utils.checkAuth('goods', 'recommend') || scope.row.deleted_at">推荐</el-checkbox>
								<el-checkbox :size="theme.size" @change="changeGoods({ action: 'boutique', id: scope.row.id})" :value="scope.row.boutique_at!=null" :disabled="!$utils.checkAuth('goods', 'boutique') || scope.row.deleted_at">精品</el-checkbox>
								<el-checkbox :size="theme.size" @change="changeGoods({ action: 'shelf', id: scope.row.id})" :value="scope.row.sold_at!=null" :disabled="!$utils.checkAuth('goods', 'shelf') || scope.row.deleted_at">上架</el-checkbox>
								<el-checkbox :size="theme.size" @change="changeGoods({ action: 'hot', id: scope.row.id})" :value="scope.row.hot_at!=null" :disabled="!$utils.checkAuth('goods', 'hot') || scope.row.deleted_at">热销</el-checkbox>
							</div>
							<p style="margin-top: 2px;">
								<el-tooltip placement="top" v-for="tag in scope.row.tags" :key="tag.id" :content="'标签关联 '+tag.goods_count+' 个商品'">
									<el-tag size="mini" type="primary" style="margin-right: 10px;">{{tag.name}}</el-tag>
								</el-tooltip>
								<el-tooltip placement="top"  v-for="discount in scope.row.goods_discounts" :key="discount.id" :content="discount.begin_at+' 至 '+discount.end_at">
									<el-tag size="mini" type="warning" style="margin-right: 10px;">{{discount.minimum}}件{{discount.type == 'DISCOUNT'?('打'+(discount.amount/10)+'折'):('减'+(discount.amount/100)+'元')}}</el-tag>
								</el-tooltip>
								<el-tooltip placement="top" v-for="coupon in scope.row.coupons" :key="coupon.id" :content="'No.'+coupon.no+' 有效期：'+coupon.available_begin_at+' 至 '+coupon.available_end_at">
									<el-tag size="mini" type="danger" style="margin-right: 10px;">满{{coupon.transaction_minimum/100}}元{{coupon.type == 'DISCOUNT'?('打'+(coupon.discount_amount/10)+'折'):('减'+(coupon.discount_amount/100)+'元')}}</el-tag>
								</el-tooltip>
							</p>
						</template>
					</el-table-column>
					<el-table-column prop="rack" label="货架" min-width="100" sortable="custom" :formatter="(r) => { return r.rack || '暂无 ~'; }"></el-table-column>
					<el-table-column prop="market_price" label="市场价" min-width="100" sortable="custom" :formatter="(r) => { return (r.market_price/100).toFixed(2)+' 元'; }"></el-table-column>
					<el-table-column prop="shop_price" label="零售价" min-width="100" sortable="custom" :formatter="(r) => { return (r.shop_price/100).toFixed(2)+' 元'; }"></el-table-column>
					<el-table-column prop="weight" label="重量(克)" min-width="100" sortable="custom"></el-table-column>
					<el-table-column prop="stock" label="库存" min-width="80" sortable="custom"></el-table-column>
					<el-table-column prop="at" label="操作时间" width="210" v-if="theme.width >= 1080">
						<el-dropdown slot="header" @command="batchAction">
							<el-button type="text">操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="create" :disabled="!$utils.create('goods')">添加商品</el-dropdown-item>
								<el-dropdown-item command="edit">编辑选中</el-dropdown-item>
								<el-dropdown-item command="export">导出选中</el-dropdown-item>
								<el-dropdown-item command="delete">删除选中</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<template slot-scope="scope">
							<template v-if="scope.row.deleted_at">
								<p>删除：{{scope.row.deleted_at}}</p>
								<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore('goods')">恢复</el-button>
							</template>
							<template v-else>
								<p v-if="scope.row.recommend_at">推荐：{{scope.row.recommend_at}}</p>
								<p v-if="scope.row.boutique_at">精品：{{scope.row.boutique_at}}</p>
								<p v-if="scope.row.sold_at">上架：{{scope.row.sold_at}}</p>
								<p v-if="scope.row.hot_at">热销：{{scope.row.hot_at}}</p>
								<p>更新：{{scope.row.updated_at}}</p>
								<p>创建：{{scope.row.created_at}}</p>
							</template>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					slot="footer"
					@size-change="(v) => { getGoods({ ...params, page: 1, perPage: v }); $refs.goods_table.bodyWrapper.scrollTop = 0; }"
					@current-change="(v) => { getGoods({ ...params, page: v }); $refs.goods_table.bodyWrapper.scrollTop = 0; }"
					:layout="$config.PAGE_LAYOUT"
					:page-sizes="$config.PAHE_SIZES"
					:page-size="params.perPage"
					:current-page="params.page"
					:total="params.total"
					background>
				</el-pagination>
			</main-table>
			<!-- 批量编辑 -->
			<el-dialog title="批量编辑" :visible.sync="batch_dialog" @closed="$refs['batch_edit_form'].resetFields()" :fullscreen="theme.width <= 380">
				<el-form ref="batch_edit_form" label-width="80px" :model="batch_edit_form" :size="theme.size" status-icon>
					<el-form-item label="修改字段" prop="field" required>
						<el-select v-model="batch_edit_form.field" placeholder="请选择修改字段" filterable clearable>
							<el-option label="转移到其它类目" value="category_id"></el-option>
							<el-option label="转移到其它品牌" value="brand_id"></el-option>
							<el-option label="转移到其它店铺" value="shop_id"></el-option>
							<el-option label="复制到其他店铺" value="copy"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="目标分类" prop="value" required v-if="batch_edit_form.field == 'category_id'">
						<el-cascader placeholder="请选择目标类目" v-model="batch_edit_form.value" :options="tree_categories" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
					</el-form-item>
					<el-form-item label="目标品牌" prop="value" required v-if="batch_edit_form.field == 'brand_id'">
						<el-select v-model="batch_edit_form.value" placeholder="请选择目标品牌" filterable clearable>
							<el-option v-for="brand in brands" :key="brand.id" :label="brand.name" :value="brand.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="目标店铺" prop="value" required v-if="batch_edit_form.field == 'shop_id'">
						<el-select v-model="batch_edit_form.value" placeholder="请选择目标店铺" filterable clearable>
							<el-option v-for="brand in brands" :key="brand.id" :label="brand.name" :value="brand.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="目标店铺" prop="value" required v-if="batch_edit_form.field == 'copy'">
						<el-select v-model="batch_edit_form.value" placeholder="请选择目标店铺" filterable clearable>
							<el-option v-for="brand in brands" :key="brand.id" :label="brand.name" :value="brand.id"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submitForm('batch_edit_form')">确 定</el-button>
				</div>
			</el-dialog>
			<goods-show ref="show_dialog" @refresh="getGoods(params)"></goods-show>
		</template>
	</div>
</template>

<style type="text/css">
	.mobile {
		display: flex; flex-direction: column; justify-content: space-between; height: 100%;
	}
	.mobile .head {
		display: flex; line-height: 45px; color: #666; font-size: 14px; border-bottom: 1px solid #ccc; padding: 0 10px; justify-content: space-between; background-color: #fff;
	}
	.mobile .wrap {
		flex: 1; background-color: #f5f5f5; overflow: auto;
	}
	.mobile .item {
		border-bottom: 10px solid #f5f5f5; padding: 10px; background-color: #fff;
	}
	.mobile .title {
		font-size: 16px;
		color: #333;
		line-height: 1.5;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
	}
	.mobile .mate {
		color: #666;
		line-height: 2;
		font-size: 12px;
	}
	.mobile .mate del {
		text-decoration: line-through;
	}
	.w-e-full-screen-container {
		z-index: 9;
	}
	.thumb {
		width: 100px;
		height: 100px;
		font-size: 30px;
		line-height: 100px;
		text-align: center;
		background: #f1f1f1;
	}
	.scrollbar-wrapper .el-scrollbar__wrap {
		overflow-x: auto;
	}
	.view-tag {
		color: #fff;
		font-size: 12px;
		padding: 0 10px;
		background-color: rgba(0, 0, 0, .6);
		position: absolute;
		right: 0;
		top: 0;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import vueQr from 'vue-qr';
	import mainTable from '@/components/main-table';
	import goodsShow from './show';

	export default {
		components: {
			vueQr,
			goodsShow,
			mainTable
		},
		computed: {
			...mapState(['theme', 'admin_user', 'goods']),
			tree_categories () {
				return this.$utils.buildTree(this.goods.categories || []);
			},
			goods_types () {
				return this.goods.goods_types || [];
			},
			brands () {
				return this.goods.brands || [];
			}
		},
		methods: {
			async remoteMethod (query) {
				if (query !== '') {
					this.tags_loading = true;
					const res = await this.$http.get(this.$api.URI_TAGS, { params: {action: 'search', word: query} });
					this.tags = res.data.result.map((tag) => { return tag.name; });
					this.tags_loading = false;
				} else {
					this.tags = [];
				}
			},
			onRefresh() {
				setTimeout(() => {
					this.$toast('刷新成功');
					this.pull_down_loading = false;
					this.count++;
				}, 1000);
			},
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getGoods(p);
			},
			onRestore (r) {
				this.$confirm(`确定要恢复【${r.title}】此条内容吗?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.handleGoods('restore', r);
				});
			},
			async getGoods (p, l = false) {
				const res = await this.$http.get(this.$api.URI_GOODS, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.tables = result.data;
				result.shops && (this.shops = result.shops);
				this.params = {
					...p,
					total: result.total,
					perPage: parseInt(result.per_page),
					page: parseInt(result.current_page)
				}
			},
			async changeGoods (r) {
				const res = await this.$http.get(this.$api.URI_GOODS, {params: r, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
				this.getGoods(this.params);
			},
			async handleGoods (a, r) {
				const res = await this.$http.get(this.$api.URI_GOODS, {params: {action: a, id: r.id}, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
				this.getGoods(this.params);
			},
			async submitGoods (data) {
				const res = await this.$http.post(this.$api.URI_GOODS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getGoods(this.params);
						this.batch_edit_form = false;
					}
				});
			},
			submitForm (r) {
				this.$refs[r].validate(async (valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (r) {
						case 'batch_edit_form':
							this.submitGoods(this.edit_form);
						break;
					}
				});
			},
			batchAction (m) {
				if (m === 'create') return this.$refs['show_dialog'].open();
				const { selection } = this.$refs.goods_table;
				if (!selection.length) return this.$message.error('请选择操作内容');
				switch (m) {
					case 'edit':
						this.batch_dialog = true;
						this.edit_form.id = selection.map((item) => { return item.id });
						break;
					case 'export':
							this.$confirm(`此操作将导出${selection.length}条内容, 是否继续？`, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								selection.sort((a, b) => {
									a.category_id - b.category_id
								}).sort((a, b) => {
									a.brand_id - b.brand_id
								}).sort((a, b) => {
									a.goods_type_id - b.goods_type_id
								}).forEach((c) => {
									c.brand_name = c.brand ? c.brand.name : '未知';
									c.category_name = c.category ? c.category.name : '未知';
									c.goods_type_name = c.goods_type ? c.goods_type.name : '未知';
									c.market_price = c.market_price/100;
									c.shop_price = c.shop_price/100;
								});
								var file = "商品导出" + this.$moment().format('YYYYMMDDHHmmss');
								var header = [['ID', '分类', '品牌', '类型', '名称', '编码', '市场价', '零售价']];
								var filter = ['id', 'category_name', 'brand_name', 'goods_type_name', 'title', 'number', 'market_price', 'shop_price'];
								this.$utils.handleDownload(header, filter, selection, file, "产品明细");
							})
						break;
					case 'delete':
							this.$confirm(`此操作将永久删除${selection.length}条内容, 是否继续？`, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(async () => {
								let index = 0;
								let notify = this.$notify({
									title: '提示',
									dangerouslyUseHTMLString: true,
									message: '<p>正在准备删除...</p>',
									showClose: false,
									duration: 0
								});
								for (var o in selection) {
									let { id } = selection[o];
									index += 1;
									notify.message = '<p>正在删除 ' + index + ' / ' + selection.length + '</p>';
									await this.$http.get(this.$api.URI_GOODS, {params: {action: "delete", id}, headers: {loading: true}});
								}
								if (index === selection.length) {
									notify.message = '<p>删除完成！</p>';
									setTimeout(() => {
										notify.close();
										this.getGoods(this.params);
									}, 3000);
								}
							});
						break;
				}
			}
		},
		data() {
			return {
				tags: [],
				shops: [],
				tables: [],
				batch_edit_form: {},
				tags_loading: false,
				batch_dialog: false,
				pull_down_loading: false,
				params: {
					page: 1,
					perPage: 10,
					date_type: 'created',
					type: []
				}
			}
		},
		async created () {
			this.getGoods(this.params, true);
		}
	};
</script>