<template>
	<div style="height: 100%; display: flex; flex-direction: column;">
		<div style="flex: 1;">
			<el-table class="scroll-wrapper" height="100%" :header-cell-style="{backgroundColor: '#e1d6ba', color: '#666'}" :data="discounts" :size="theme.size">
				<el-table-column label="类型" prop="type" min-width="60"></el-table-column>
				<el-table-column label="最小限额" prop="minimum" min-width="80"></el-table-column>
				<el-table-column label="方式" prop="method" min-width="60"></el-table-column>
				<el-table-column label="优惠额度" prop="amount" min-width="80"></el-table-column>
				<el-table-column label="一次下单" prop="once" min-width="60"></el-table-column>
				<el-table-column label="多次下单" prop="more" min-width="60"></el-table-column>
				<el-table-column label="促销时间" min-width="140">
					<template slot-scope="scope">
						<p>{{scope.row.begin_at}}</p>
						<p>{{scope.row.end_at}}</p>
					</template>
				</el-table-column>
				<el-table-column min-width="60" fixed="right">
					<el-link slot="header" type="primary" @click="discounts_dialog = true; discount = {...discount, goods_id: form.id, number: form.number }" :disabled="!$utils.create($api.URI_GOODS_DISCOUNTS)">添加</el-link>
					<el-popconfirm slot-scope="scope" title="确定要删除这条记录吗？" @confirm="delDiscount(scope.row)">
						<el-link slot="reference" type="primary" icon="el-icon-delete" :disabled="!$utils.delete($api.URI_GOODS_DISCOUNTS)">删除</el-link>
					</el-popconfirm>
				</el-table-column>
			</el-table>
		</div>
		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getClientFees({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getClientFees({ ...params, page: v }) }"
			background>
		</el-pagination>
		<el-dialog width="520px" :title="goods.title+'限时促销'" :visible.sync="discounts_dialog" :fullscreen="theme.width <= 520" append-to-body destroy-on-close>
			<el-form ref="discounts_form" label-width="80px" :model="discount" :rules="discount_rules" :size="theme.size" status-icon>
				<el-form-item label="条件门槛" prop="minimum">
					<el-input v-model.number="discount.minimum" label="请输入最低限度门槛">
						<el-select slot="prepend" style="width: 100px;" v-model="discount.type" placeholder="请选择条件类型">
							<el-option label="必须等于" :value="0"></el-option>
							<el-option label="小于等于" :value="1"></el-option>
							<el-option label="大于等于" :value="2"></el-option>
						</el-select>
						<template slot="append">件</template>
					</el-input>
				</el-form-item>
				<el-form-item label="优惠额度" prop="amount" :rules="[{ required: true, message: '请输入优惠额度' }, { type: 'number', min: 1, max: discount.method ? 999999 : 10, message: '优惠额度必须大于等于1或小于等于'+(discount.method ? 999999 : 10)}]">
					<el-input v-model.number="discount.amount" placeholder="请输入优惠额度">
						<el-select slot="prepend" style="width: 100px;" v-model="discount.method" placeholder="请选择优惠类型">
							<el-option label="小计折扣" :value="0"></el-option>
							<el-option label="小计满减" :value="1"></el-option>
							<el-option label="小计固额" :value="2"></el-option>
						</el-select>
						<template slot="append" v-if="!discount.method">折</template>
						<template slot="append" v-else>分</template>
					</el-input>
				</el-form-item>
				<el-form-item label="每单数量" prop="once">
					<el-input v-model.number="discount.once" placeholder="请输入本次活动时间每订单中数量。默认0为不限制">
						<template slot="append">件</template>
					</el-input>
				</el-form-item>
				<el-form-item label="累计数量" prop="more">
					<el-input v-model.number="discount.more" placeholder="请输入本次活动时间累计下单数量。默认0为不限制">
						<template slot="append">件</template>
					</el-input>
				</el-form-item>
				<el-form-item label="活动时间" prop="date">
					<el-date-picker v-model="discount.date" type="datetimerange" value-format="yyyy-MM-dd 00:00:00" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm('discounts_form')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		props: {
			goods: {
				type: Object
			}
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			async getGoodsDiscounts (p, l = false) {
				const res = await this.$http.get(this.$api.URI_GOODS_DISCOUNTS, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.discounts = result.data;
				this.params = {
					...p,
					total: result.total,
					page: result.current_page,
					perPage: parseInt(result.per_page)
				}
			},
			async delDiscount (r) {
				const res = await this.$http.get(this.$api.URI_GOODS_DISCOUNTS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$emit('refresh');
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'discounts_form':
							var res = await this.$http.post(this.$api.URI_GOODS_DISCOUNTS, this.discount);
							var { code, msg } = res.data;
							if (code != 0) return this.$message.error(msg);
							this.getGoodsDiscounts(this.params);
							this.discounts_dialog = false;
						break;
					}
				});
			}
		},
		data() {
			return {
				discount: {
					minimum: 1,
					amount: 1,
					type: 0,
					method: 0,
					once: 0,
					more: 0
				},
				discounts: [],
				discounts_dialog: false,
				params: {
					perPage: 10
				},
				discount_rules: {
					minimum: [
						{ required: true, message: '请输入最低限度门槛' },
						{ type: 'number', min: 1, max: 999, message: '优惠金额必须大于等于1或小于等于999'}
					], 
					once: [
						{ required: true, message: '请输入一次下单数量限制' },
						{ type: 'number', min: 0, message: '一次下单数量限制必须大于等于0'}
					], 
					more: [
						{ required: true, message: '请输入多次下单数量限制' },
						{ type: 'number', min: 0, message: '多次下单数量限制必须大于等于0'}
					], 
					date: [
						{ required: true, message: '请填写活动开始结束时间' }
					]
				}
			}
		},
		mounted () {
			if (!this.goods) return false;
			const { id: goods_id } = this.goods;
			this.getGoodsDiscounts({...this.params, goods_id});
		}
	};
</script>